import React from 'react';

import { HelpArticle, SubTitle } from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';
import Link from 'components/ui/link';

import logo from 'img/help/integrations/olark-logo.png';

const Content = () => {
  return (
    <div>
      <SubTitle>What is Olark?</SubTitle>
      <p>
        <strong>Olark is a live chat web application for support and sales specialists</strong>. You
        can use it to increase your sales, provide support to your users, and acquire new leads. To
        achieve these goals Olark provide you features like: automated messaging, custom pre-chat
        surveys, detailed visitor insights, and in-depth reporting.{' '}
        <strong>
          To make your support team even more productive with Olark, you can set up LiveSession
          integration
        </strong>
        . Then when users will have technical issues with your website, you&apos;ll be able to check
        what exactly happen - session replay link will be visible from Olark. This will make your
        support actions faster, because there will be no need to ask additional questions about
        specific issue.
      </p>
      <SubTitle className="mt-5">How does this integration work?</SubTitle>
      <p>
        This integration lets you add links to session replays from LiveSession to your Olark
        account.{' '}
        <strong>
          Thanks to this solution, you can open recordings of a specific user directly from Olark
          contact profile
        </strong>
        .
      </p>
      <p>
        Every time a new session will start, our code will send an event to Olark with a link to
        session replay.
      </p>
      <SubTitle className="mt-5">How can I install this integration?</SubTitle>
      <span>To install integration, add the code below before your </span>{' '}
      <Code inline>{'</body>'}</Code> <span> closing tag:</span>
      <Code margin="1rem 0" copy>
        {`<script>
if(olark && __ls){
__ls("getSessionURL", function(url, isNew){
    if(isNew){
      olark('api.chat.updateVisitorStatus', {
          snippet: "LiveSession: " + url
      });
    }
})
}
</script>`}
      </Code>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        To make this integration works you need to have Olark and LiveSession installed on your
        website. If you haven&apos;t installed our tracking code yet check{' '}
        <Link href="/help/how-to-install-livesession-script/">this article</Link>.
      </div>
    </div>
  );
};

const related = [];

export const frontmatter = {
  metaTitle: 'LiveSession and Olark integration',
  metaDescription: 'Send LiveSession recordings to your Olark account',
  canonical: '/help/olark-integration/',
  logoStyle: { width: 100 },
  logo,
};

const Wrapped = HelpArticle(Content);
export default () => (
  <Wrapped
    related={related}
    section="Integrations"
    title={frontmatter.metaTitle}
    {...frontmatter}
  />
);
